<div class="navbar-container">
  <div class="first-row">
    <div class="left" (click)="goBack()">
      <img src="../../../../assets/images/template-icons/back.png" alt="" />
      <a>Back to {{ backTo }}</a>
    </div>
    <div class="right">
      <button
        class="cta-buttons {{ addedAll ? '' : 'disabled' }}"
        (click)="reviewData()"
        *ngIf="step === 4">
        <img
          src="../../../assets//images/sidebar/signature-white.svg"
          alt=""
          srcset="" />
        Review and send
      </button>
      <img
        src="../../../../assets/images/template-icons/sign-logo.svg"
        alt=""
        (click)="goBack()" />
    </div>
  </div>
  <div class="second-row {{ 'step-' + step }}" *ngIf="step !== 6">
    <app-steps class="center"></app-steps>
  </div>
</div>
<div class="pdf-nav" *ngIf="step === 4">
  <div class="left"></div>
  <div class="center">
    <!-- <button (click)="zoomLevel('in')">
			<img src="../../../assets//images/icons/zoom-in.svg" alt="" />
		</button>
		<button (click)="zoomLevel('out')">
			<img src="../../../assets//images/icons/zoom-out.svg" alt="" />
		</button>
		<select [value]="zoom" (change)="updateZoom($event)">
			<option value="0.25">25%</option>
			<option value="0.5">50%</option>
			<option value="0.75">75%</option>
			<option value="1" selected>100%</option>
			<option value="1.25">125%</option>
			<option value="1.5">150%</option>
			<option value="1.75">175%</option>
			<option value="2">200%</option>
			<option value="2.25">225%</option>
			<option value="2.5">250%</option>
			<option value="2.75">275%</option>
			<option value="3">300%</option>
		</select>
		<div class="divider"></div> -->
    <button (click)="pageNavi('up')">
      <img alt="" class="back-button" />
    </button>
    <div class="page-text">{{ index }} of {{ totalPages }}</div>
    <button (click)="pageNavi('down')">
      <img alt="" class="next-button" />
    </button>
  </div>
  <div class="right"></div>
</div>
