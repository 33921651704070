<div class="container">
  <div
    class="action"
    *ngIf="actions?.download"
    (click)="download(actions.download, 'path')">
    <img
      alt=""
      [style.maskImage]="
        'url(../../../assets/images/icons/download-line.svg)'
      " />
  </div>
  <div class="action" *ngIf="actions?.undue">
    <img
      alt=""
      [style.maskImage]="'url(../../../assets/images/icons/warning.svg)'" />
  </div>
  <div
    class="action"
    *ngIf="actions?.info"
    (click)="showModal(actions.info?.action, '818px', actions.info?.data)">
    <img
      alt=""
      [style.maskImage]="'url(../../../assets/images/icons/info.svg)'" />
  </div>
  <div
    class="action edit"
    *ngIf="actions?.edit"
    (click)="showModal('editUser', '636px', actions.edit)">
    <img
      alt=""
      [style.maskImage]="'url(../../../assets/images/icons/edit.svg)'" />
  </div>
  <div
    class="action delete"
    *ngIf="actions?.delete"
    (click)="showModal('deleteUser', '588px', actions.delete)">
    <img
      alt=""
      [style.maskImage]="'url(../../../assets/images/icons/delete.svg)'" />
  </div>
  <div
    class="action delete"
    *ngIf="actions?.delete_doc"
    (click)="
      showModal('deleteDocument', '588px', data); $event.stopPropagation()
    ">
    <img
      alt=""
      [style.maskImage]="'url(../../../assets/images/icons/delete.svg)'" />
  </div>
  <div
    class="action choice {{ disable ? 'disable' : '' }}"
    *ngIf="actions?.choices">
    <button
      class="btn-actions {{ disable ? 'disable' : '' }}"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="disable ? null : moreActions">
      Actions
      <img
      src="../../../assets/images/icons/gear.png"
        alt=""
         />
    </button>
    <mat-menu #moreActions="matMenu">
      <button
        mat-menu-item
        (click)="shareFile(actions.choices?.share)"
        *ngIf="actions?.choices?.share">
        <img
          alt=""
          [style.maskImage]="'url(../../../assets/images/icons/share.svg)'" />
        Share
      </button>
      <button
        mat-menu-item
        (click)="modifyFile(actions.choices?.modify)"
        *ngIf="actions?.choices?.modify">
        <img
          alt=""
          [style.maskImage]="'url(../../../assets/images/icons/modify.svg)'" />
        Modify
      </button>

      <button
        mat-menu-item
        *ngIf="actions?.choices?.move"
        (click)="moveFile(actions.choices?.move)">
        <img
          alt=""
          [style.maskImage]="'url(../../../assets/images/icons/move.svg)'" />
        Move
      </button>

      <!-- Hidden for now
        <button
        mat-menu-item
        *ngIf="actions?.choices?.duplicate"
        (click)="createDuplicateDirectory()"
      >
        <img src="../../../assets/images/icons/duplicate.svg" alt="" />
        Duplicate
      </button> -->
      <button
        mat-menu-item
        *ngIf="actions?.choices?.download"
        (click)="download(actions.choices?.download)">
        <img
          alt=""
          [style.maskImage]="
            'url(../../../assets/images/icons/download.svg)'
          " />
        Download
      </button>
      <button
        mat-menu-item
        *ngIf="actions?.choices?.view_members"
        (click)="
          fileActionModal('viewMembers', '520px', actions.choices?.view_members)
        ">
        <img
          alt=""
          [style.maskImage]="
            'url(../../../assets/images/icons/view-team-members.svg)'
          " />
        View Members
      </button>
      <button
        mat-menu-item
        *ngIf="actions?.choices?.delete"
        (click)="
          fileActionModal(
            'delete-selected-files',
            '588px',
            actions.choices?.delete
          )
        ">
        <img
          src="../../../assets/images/icons/delete.svg"
          alt=""
          [style.maskImage]="'url(../../../assets/images/icons/delete.svg)'" />
        Delete
      </button>
    </mat-menu>

    <mat-menu #share="matMenu">
      <button mat-menu-item>
        <fa-icon [icon]="faUser"></fa-icon>
        Share to user
      </button>

      <button mat-menu-item>
        <fa-icon [icon]="faUsers"></fa-icon>
        Share to group
      </button>
      <button mat-menu-item>
        <fa-icon [icon]="faLock"></fa-icon>
        Set Share access
      </button>
      <button mat-menu-item>
        <fa-icon [icon]="faCalendarCheck"></fa-icon>
        Share until
      </button>
    </mat-menu>

    <mat-menu #export="matMenu">
      <button mat-menu-item>PDF</button>
      <button mat-menu-item>Excel</button>
      <button mat-menu-item>Word Document</button>
    </mat-menu>
  </div>
</div>
