import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.sass'],
})
export class AdminNavbarComponent implements OnInit {
  @Output() viewAccountEvent = new EventEmitter<boolean>();
  @Output() toggleSideNavEvent = new EventEmitter<boolean>();
  toggle: boolean = true;
  searchQuery: string = '';
  isFormaCommercial = environment.flag.commercial;
  pictureSrc: any = '../../../assets/images/avatar.png';
  miniPictureSrc: any = '../../../assets/images/icons/accountAvatar.png';
  private sub: Subscription = new Subscription();
  isTabletWidth = false;
  isPhoneWidth = false;

  constructor(
    public dialog: MatDialog,
    private _authService: AuthenticationService,
    private _navigationService: NavigationService,
    private _filesService: FilesService,
    private _router: Router,
    private _userService: UsersService
  ) {}

  ngOnInit(): void {
    if (screen.width >= 375 && screen.width <= 600) {
      this.isPhoneWidth = true;
    } else if (screen.width >= 600 && screen.width <= 912) {
      this.isTabletWidth = true;
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
    }

    this.sub.add(
      this._userService.displayPhoto$.subscribe(i => {
        this.pictureSrc = i || '../../../assets/images/avatar.png';
        this.miniPictureSrc =
          i || '../../../assets/images/icons/accountAvatar.png';
      })
    );
  }

  toggleSideNav() {
    this.toggle = !this.toggle;
    this.toggleSideNavEvent.emit(this.toggle);
  }

  showUploadModal(data: any) {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '882px',
      data: { action: 'upload-documents', file: data },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this._filesService.reset();
      if (result === 'more') {
        this.showUploadDragAndDropModal();
      }
    });
  }

  logout() {
    this._navigationService.goTologinPage();
    this._authService.logout();
    sessionStorage.removeItem('token');
    this._authService.userRole = '';
    this._authService.setRole(this._authService.userRole);
    this._router.navigateByUrl('/login');
  }
  onFileSelected(data: any) {
    if (data.path[0].files.length <= 0) {
      return;
    }
    const regex =
      /.*\.(jpg|JPG|png|PNG|pdf|PDF|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|ppt|PPT|pptx|PPTX)$/g;

    if (data.path) {
      const files = data.path[0].files.filter((file: File) =>
        regex.test(file.name)
      );
      this._filesService.uploadFiles(files);
      this.showUploadModal(data.path[0].files);
    } else {
      const files = data.target.files.filter((file: File) =>
        regex.test(file.name)
      );
      this._filesService.uploadFiles(files);
      this.showUploadModal(files);
    }
  }
  upload(type: string) {
    switch (type) {
      case 'files': {
        document.getElementById('fileUpload')?.click();
        break;
      }
      case 'folder': {
        document.getElementById('folderUpload')?.click();
        break;
      }
      default: {
        break;
      }
    }
  }
  viewAccount() {
    this._router.navigateByUrl('/account');
  }
  search() {
    this._router.navigateByUrl(`/search/${this.searchQuery}`);
  }
  
  advancedSearch() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '70svw',
      data: { action: 'advanced-search' },
    });
    dialogRef?.afterClosed()?.subscribe(data => {
      console.log(data);
    });
  }

  showUploadDragAndDropModal() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: { action: 'drag-drop-upload' },
    });

    dialogRef?.afterClosed()?.subscribe(data => {
      if (data) {
        this._filesService.uploadFiles(data);
        this.showUploadModal(data);
      }
    });
  }
}
