import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileDataModel } from '../models/file-data.model';
import { FileTableModel } from '../models/file-table.model';
import { UserTableModel } from '../models/user-table.model';
import FileDataJson from '../../../dummy-data/files.json';
import { AuthenticationService } from './authentication.service';
import { SearchResults } from '../models/search-results.model';
import { NavigationService } from './navigation.service';
import { FolderModel } from '../models/folder.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { Actions } from '../models/Actions.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly folder = environment.apiConfig.folder.url;
  private readonly file = environment.apiConfig.file.url;
  private readonly directory = environment.apiConfig.directory.url;
  private readonly _fileUrl = `${this.baseUrl}${this.file}`;
  private readonly _folderUrl = `${this.baseUrl}${this.folder}`;
  private readonly _directoryUrl = `${this.baseUrl}${this.directory}`;
  private readonly _publishUrl = `${this.baseUrl}${this.directory}${environment.apiConfig.publish.url}`;
  imgSrc: string = '';
  title: string = '';

  addButton: string = 'add sample';

  filesCols: string[] = [
    ' ',
    'title',
    'tags',
    'Date Modified',
    'Date Created',
    'actions',
  ];

  filesData: Object[] = FileDataJson;
  files1Data: FileTableModel[] = [];
  fileData: FileDataModel[] = [];
  private searchResults = new BehaviorSubject<SearchResults>({
    searchQuery: '',
    data: [],
  });
  searchResults$ = this.searchResults.asObservable();
  searchQuery: string = '';

  file1Data: FileDataModel[] = [];
  filesAysnc = new Subject<any>();
  private files = new BehaviorSubject<FileDataModel[]>([]);
  files$ = this.files.asObservable();
  resultsLength: number = 0;

  private recentfiles = new BehaviorSubject<FileTableModel[]>([]);
  recentfiles$ = this.recentfiles.asObservable();

  // Used for Files and Folders
  private filesAndFolderAsync = new BehaviorSubject<any[]>([]);
  filesAndFolderAsync$ = this.filesAndFolderAsync.asObservable();
  filesAndFolder: any[] = this.filesAndFolderAsync.value;
  // Used for Folders
  folders: any[] = [];
  private foldersAsync = new BehaviorSubject<any[]>([]);
  folderAsync$ = this.foldersAsync.asObservable();
  node: any;
  prevRecentFiles: any[] = [];

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService,
    private _breadcrumbs: BreadcrumbsService,
    private _sanitizer: DomSanitizer
  ) {}

  async uploadFiles(files: FileList) {
    this.formatFiles(files);
    this.pushData();
    const length = this.fileData.length;
    const parent =
      this._breadcrumbs.breadcrumbsData.length > 1
        ? this._breadcrumbs.breadcrumbsData[
            this._breadcrumbs.breadcrumbsData.length - 1
          ].id
        : '';
    for (let i = 0; i < length; i++) {
      if (this.fileData[i].type == 'folder') {
        const children = this.fileData[i].child ?? [];
        const percentile = 100 / children.length ?? 1;
        this.fileData[i].parent = parent;
        await this.uploadFolderData(this.fileData[i], i, percentile);
      } else {
        this.fileData[i].level = 1;
        await this.uploadDirectory(this.fileData[i], 'file', i);
      }
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formatFiles(files: any) {
    let fileDatum: any = {};

    if (files[0]?.webkitRelativePath !== '') {
      fileDatum.child = [];
      let childDatum: any = {};
      const folders: any = {};
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const paths = file?.webkitRelativePath.split('/') || [];

        childDatum = {};
        childDatum.name = file?.name;
        childDatum.type = 'file';
        childDatum.size = this.formatBytes(file?.size || 0);
        childDatum.progress = 0;
        childDatum.file = file;
        childDatum.parent = paths.length >= 2 ? paths[paths.length - 2] : null;

        let folder: any = {};
        if (folders[paths[paths.length - 2]]) {
          folders[paths[paths.length - 2]].size += file?.size || 0;
          folders[paths[paths.length - 2]].child.push(childDatum);
        } else {
          folder.name = paths[paths.length - 2];
          folder.type = 'folder';
          folder.progress = 0;
          folder.child = [];
          folder.parent = paths.length >= 3 ? paths[paths.length - 3] : null;
          folder.size = file?.size || 0;
          folder.child.push(childDatum);
          folders[folder.name] = { ...folder };
        }
      }

      const formattedData: any = {};
      const folderKeys = Object.keys(folders);
      folderKeys.forEach(key => {
        const folder = folders[key];
        if (folder.parent) {
          if (!formattedData[folder.parent]?.name) {
            formattedData[folder.parent] = { ...folder };
            formattedData[folder.parent].child = [];
            formattedData[folder.parent].name = folder.parent;
            formattedData[folder.parent].size = 0;
            formattedData[folder.parent].progress = 0;
            delete formattedData[folder.parent].parent;
          }

          formattedData[folder.parent].child.push({ ...folder });
          formattedData[folder.parent].size += folder.size;

          if (formattedData[key]) {
            delete formattedData[key];
          }
        } else if (!formattedData[key]) {
          formattedData[key] = { ...folder };
        }
      });
      Object?.keys(formattedData).forEach(key => {
        formattedData[key].size = this.formatBytes(formattedData[key].size);
        this.fileData.push(formattedData[key]);
      });
    } else {
      for (let i = 0; i < files.length; i++) {
        fileDatum = {};
        if (this._breadcrumbs.breadcrumbsData.length > 1) {
          fileDatum.parent =
            this._breadcrumbs.breadcrumbsData[
              this._breadcrumbs.breadcrumbsData.length - 1
            ].id;
        }
        fileDatum.name = files[i]?.name;
        fileDatum.type = 'file';
        fileDatum.size = this.formatBytes(files[i]?.size || 0);
        fileDatum.progress = 0;
        fileDatum.file = files[i];

        this.fileData.push(fileDatum);
      }
    }
  }

  setFiles(data: FileDataModel[]) {
    this.files.next(data);
  }

  setSearchResults(data: SearchResults) {
    this.searchQuery = data.searchQuery;
    this.searchResults.next(data);
  }

  pushData() {
    this.setFiles(this.fileData);
    this.filesAysnc.next(this.fileData);
  }

  async uploadFile(file: any, index: number) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._fileUrl}/create`,
        this.formatFileDTO(file, index, 'file'),
        {
          reportProgress: true,
          observe: 'events',
        }
      )
    );
  }

  async uploadFolder(file: any, index: number) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._folderUrl}/create`,
        this.formatFolderDTO(file, index, 'folder'),
        {
          reportProgress: true,
          observe: 'events',
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
    );
  }

  async uploadDirectory(data: any, type: string, index: number) {
    const id = this._authService.userId;

    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._directoryUrl}/user/create/` + id,
        this.formatDirectoryDTO(data, index, type),
        {
          reportProgress: true,
          observe: 'events',
        }
      )
    );
  }

  formatDirectoryDTO(data: any, index: number, type: string) {
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
    }
    formData.append('name', data.name);
    formData.append('description', data.name);
    if (data.parent) {
      formData.append('parent', data.parent);
    }
    if (typeof data.size === 'number') {
      formData.append('size', this.formatBytes(data.size));
    } else {
      formData.append('size', data.size);
    }
    formData.append('index', index + '');
    formData.append('type', type);
    formData.append('progress', '0');
    return formData;
  }

  formatFolderDTO(file: any, index: number, type: string = 'folder') {
    return {
      index: index,
      type: type,
      folderName: file.name,
      createdBy: 'string',
      updatedBy: 'string',
      description: file.name,
      parent: file.type === 'file' ? '' : file.parent || '',
      level: file.type === 'file' ? 1 : file.level || 1,
      tags: [],
      userGroup: '',
      createdAt: 0,
      updatedAt: 0,
    };
  }

  // For future use....
  formatFileDTOFormData(file: any, index: number, type: string = 'file') {
    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('description', file.name);
    formData.append(
      'parent',
      file.type === 'file' ? undefined : file.parent || undefined
    );
    formData.append('level', file.type === 'file' ? 1 : file.level || 1);
    formData.append('index', index + '');
    formData.append('size', file.size);
    formData.append('type', type);
    formData.append('file', file.file);
    return formData;
  }

  // For current version only
  formatFileDTO(file: any, index: number, type: string = 'file') {
    return {
      index: index,
      type: type,
      name: file.name,
      createdBy: 'string',
      updatedBy: 'string',
      description: '',
      parent: file.type === 'file' ? '' : file.parent || '',
      level: file.type === 'file' ? 1 : file.level || 1,
      tags: [],
      userGroup: '',
      createdAt: 0,
      updatedAt: 0,
    };
  }

  reset() {
    this.fileData = [];
  }

  updateProgress(index: number, progress: number, errorMessage: string = '') {
    if (index === -1) {
      const loc = this.fileData.findIndex(data => {
        let progress = data.progress ?? 10;
        return progress !== 100 && progress >= 0;
      });
      let location = loc === -1 ? this.fileData.length - 1 : loc;
      if (this.fileData[location]?.type === 'file') {
        this.fileData[location].progress = progress;
        this.fileData[location].error = errorMessage;
      } else {
        this.fileData[location].numErrors =
          (this.fileData[location].numErrors ?? 0) + 1;
        this.fileData[location].progress = progress;
        this.fileData[
          location
        ].error = `${errorMessage} (${this.fileData[location].numErrors})`;
      }
    } else {
      this.fileData[index].progress = progress;
    }

    this.pushData();
  }

  async uploadFolderData(file: any, index: number, percentile: number) {
    const { child } = file;
    const folderData: any = await this.uploadDirectory(file, 'folder', index);
    for (let i = 0; i < child.length ?? [].length; i++) {
      child[i].parent = folderData.body.data.id;
      if (child[i].type == 'folder') {
        await this.uploadFolderData(child[i], index, percentile / child.length);
      } else {
        await this.uploadDirectory(child[i], 'file', index);
      }
      this.updateProgress(index, percentile * (i + 1));
    }
    return '';
  }

  formatFile(
    files: FileDataModel[],
    filter: boolean = true,
    isShared?: boolean
  ) {
    return files
      .filter((file: FileDataModel) => {
        if (!filter) {
          return true;
        }
        return file.createdBy == this._authService.userId;
      })
      .map((file: any) => {
        const choices: Actions = {
          duplicate: file,
          download: file,
          view_members: file,
        };

        if (!file.isPublished) {
          choices.delete = [file];
        }

        if (this.checkIfUserViewer(file)) {
          choices['modify'] = file;
          choices['advance_tagging'] = file;
          choices['move'] = file;
          choices['share'] = [file];
        }

        if (file.isPublished) {
          delete choices['share'];
          delete choices['move'];
        }

        const formattedFile: FileTableModel = {
          id: file.id || '',
          title: file.name || '',
          date_created: file.createdAtFormatted?.split(',')[0],
          date_modified: file.updatedAtFormatted?.split(',')[0],
          file_type: 'file',
          tags: file.tags,
          fileExtension: file.fileExtension,
          actions: {
            choices: choices,
          },
        };

        return formattedFile;
      });
  }

  formatSearchResults(files: FileDataModel[]) {
    return files.map((file: FileDataModel) => {
      const createdDate = new Date(file.createdAt as string);
      const updatedAt = new Date(file.updatedAt as string);
      const formattedFile: FileTableModel = {
        id: file.id || '',
        title: file.name || '',
        date_created: createdDate.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
        date_modified: updatedAt.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
        file_type: 'file',
        fileExtension: file.fileExtension,
        tags: file.tags,
        actions: {
          choices: {
            modify: file,
            advance_tagging:file,
            share: file,
            move: file,
            duplicate: file,
            download: file,
            view_members: file,
            img_view: file.properties,
          },
        },
      };

      return formattedFile;
    });
  }

  formatRecentFile(files: FileDataModel[]) {
    return files.map((file: FileDataModel) => {
      const formattedFile: FileTableModel = {
        id: file.id || '',
        name: file.name || '',
        date: file.createdAtFormatted?.split(',')[0],
        tags: file.tags || [],
        fileExtension: file.fileExtension,
        img_properties: file.properties,
        actions: {
          download: file,
          info: { data: file, action: 'recent-doc-info' },
        },
      };

      return formattedFile;
    });
  }

  publishFile(fileId: string, isPublished: boolean) {
    return this._httpClient.post<any>(this._publishUrl + '/' + fileId, {
      isPublished: isPublished,
    });
  }

  getAllFiles() {
    let params = new HttpParams();
    params = params.append('skip', 0);
    return this._httpClient.get<any>(this._fileUrl + '/all', { params });
  }

  getFile(fileId?: string) {
    return this._httpClient.get<any>(`${this._directoryUrl}/${fileId}`);
  }

  filterSharedDocs(data: any) {
    let shareChoice;
    const userGroupMatched = data.userGroups.filter((workflowGroup: any) =>
      this._authService.userGroups.some(e => {
        return e.userGroupId == workflowGroup.id;
      })
    );

    const userMatched = data.users
      .filter((user: any) => {
        return user.id == this._authService.userId;
      })
      .map((user: any) => {
        return { userId: user.id, role: user.role };
      });
    if (userMatched.length > 0) {
      switch (userMatched[0]['role']) {
        case 'EDITOR':
          return (shareChoice = {
            download: data,
            share: data,
            modify: data,
            advance_tagging: data,
            view_members: data,
          });
          break;
        case 'VIEWER':
          shareChoice = {
            download: data,
            view_members: data,
          };
          break;
        default:
          break;
      }
    }
    userGroupMatched.forEach((group: any) => {
      switch (group.role) {
        case 'EDITOR': {
          shareChoice = {
            download: data,
            share: data,
            modify: data,
            advance_tagging: data,
            view_members: data,
          };
          return;
        }
        case 'VIEWER': {
          shareChoice = {
            download: data,
            view_members: data,
          };
          break;
        }
        default: {
          break;
        }
      }
    });

    return shareChoice;
  }

  formatFilesAndFolderData(
    data: any[],
    workflow: boolean = false,
    all: boolean = false
  ) {
    return data
      .filter(file => (workflow ? !!file.workflow : !!!file.workflow) || all)
      .map(datum => {
        const userGroupMatched = this.filterSharedDocs(datum);

        const choices: Actions = {
          share: datum,
          move: datum,
          duplicate: datum,
          download: datum,
          view_members: datum,
        };

        if (datum?.type === 'file') {
          choices.share = [datum];
        }

        if (!datum.isPublished) {
          choices.delete = [datum];
        }

        if (this.checkIfUserViewer(datum)) {
          choices['modify'] = datum;
          choices['advance_tagging'] = datum;
        }

        const nonAuthorChoices: Actions = {
          download: datum,
          view_members: datum,
        };
        return {
          id: datum.id,
          title: datum.name,
          date_modified: datum.updatedAtFormatted?.split(',')[0],
          file_type: datum.type,
          size: datum.size || '0 KB',
          tag: datum.tags,
          groups: datum.userGroups,
          indexed: datum.indexed,
          ocr: datum.ocr ? true : false,
          fileExtension: datum.fileExtension,
          img_properties: datum.properties,
          createdBy: datum.createdBy,
          isQrAdded: datum.isQrAdded || false,
          actions: {
            choices:
              datum.createdBy == this._authService.userId
                ? choices
                : userGroupMatched
                ? userGroupMatched
                : nonAuthorChoices,
          },
          sort: datum.updatedAt,
        };
      });
  }

  setFilesAndFolderAsync(data: any[]) {
    this.filesAndFolderAsync.next(data);
  }

  getAllFilesAndFolder(includeParent: boolean = true) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all?sort=0&limit=0&includeParent=${includeParent}`
    );
  }

  formatFolder(folders: any[], child = false) {
    const format = folders.reduce((array, folder) => {
      if (folder.type === 'file') return array;
      const data: any = {
        id: folder.id,
        name: folder.name,
      };
      if (!folder.parent || child) {
        array.push(data);
      }
      return array;
    }, []);

    return format;
  }

  setFolderAsync(data: any[]) {
    this.foldersAsync.next(data);
  }

  getAllFolder(includeParent: boolean = true) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all?type=folder&limit=0&includeParent=${includeParent}`
    );
  }
  getRecentFiles() {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all?type=file&includeParent=false&limit=7&sort=0`
    );
  }

  searchFiles(query: string = this.searchQuery) {
    return this._httpClient.get<any>(
      this._directoryUrl + '/all?type=file&includeParent=false&query=' + query
    );
  }

  getUserFiles() {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user?type=file&hasTags=true&fileExtension=pdf&limit=0&includeParent=false&userId=${this._authService.userId}`
    );
  }

  getGroupFiles() {
    let userGroupIds: string[] = [];
    this._authService.userGroups.forEach(i => {
      userGroupIds.push(i.userGroupId);
    });

    let params = new HttpParams();
    params = params.append('userGroupIds', userGroupIds.join(','));

    return this._httpClient.get<any>(
      this._directoryUrl + '/all/user-group?includeParent=false',
      {
        params: params,
      }
    );
  }

  getGroupData() {
    let userGroupIds: string[] = [];
    this._authService.userGroups.forEach(i => {
      userGroupIds.push(i.userGroupId);
    });

    let params = new HttpParams();
    params = params.append('userGroupIds', userGroupIds.join(','));

    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user-group?includeParent=false`,
      {
        params: params,
      }
    );
  }

  getAllFolderFiles(id: string) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user?parent=${id}&userId=${this._authService.userId}`
    );
  }

  getGuestFolderView(id:string){
    return this._httpClient.get<any>(
      `${this.baseUrl}/directory/all/folder/${id}`
    )
  }

  openFolderOnTree(folder: any) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user?type=folder&limit=0&parent=${folder.id}&userId=${this._authService.userId}`
    );
  }

  setRecentFiles(recentFiles: any[]) {
    this.prevRecentFiles = recentFiles;
    this.recentfiles.next(recentFiles);
  }

  formatNode(folders: any[], node: any, subchild: any[], level = 0) {
    const length = folders.length;

    for (let i = 0; i < length; i++) {
      if (level === 0) {
        this._breadcrumbs.emptyBreadcrumb();
      } else if (i !== 0) {
        this._breadcrumbs.removeBreadcrumbData();
      }

      this._breadcrumbs.setBreadcrumbsData({
        title: folders[i].name,
        id: folders[i].id,
      });

      if (folders[i].id === node.id) {
        folders[i] = { ...folders[i], children: subchild };
        break;
      }

      if (folders[i].children && folders[i].children.length !== 0) {
        folders[i].children = this.formatNode(
          folders[i].children,
          node,
          subchild,
          level + 1
        );
        break;
      }
    }

    return folders;
  }

  async addUserGroupToDirectory(id: string, userGroupId: string) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._directoryUrl}/add/user-group/${id}/${userGroupId}`,
        {}
      )
    );
  }

  async updateDirectory(directory: any) {
    await lastValueFrom(
      this._httpClient.put<any>(`${this._directoryUrl}/update`, directory)
    );
  }

  async duplicateDirectory(directory: any) {
    await lastValueFrom(
      this._httpClient.post<any>(`${this._directoryUrl}/create`, directory)
    );
  }

  downloadFile(name: string, data: any) {
    const downloadLink = document.createElement('a');
    downloadLink.target = '_self';
    downloadLink.href = window.URL.createObjectURL(data);
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  downloadPDF(name: string, data: any) {
    const newBlob = new Blob([data], { type: 'application/pdf' });
    const downloadLink = document.createElement('a');
    downloadLink.target = '_self';
    downloadLink.href = window.URL.createObjectURL(newBlob);
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  fileExists(url: string): Observable<boolean> {
    return this._httpClient.get(url).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  async deleteFile(id: string) {
    await lastValueFrom(
      this._httpClient.delete<any>(`${this._directoryUrl}/${id}`)
    );
    this.getRecentFiles();
  }

  deleteBulkFile(idsArray: string[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids: idsArray,
      },
    };
    return this._httpClient.delete<any>(`${this._directoryUrl}/bulk`, options);
  }

  createFolder(folder: FolderModel) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/user/create/${this._authService.userId}`,
      folder
    );
  }

  updateSharingPermission(id: string, data: any) {
    return this._httpClient.put<any>(
      `${this._directoryUrl}/update/share-permission/${id}`,
      data
    );
  }

  moveDirectory(id: string, parent: string) {
    return this._httpClient.post<any>(`${this._directoryUrl}/move/${id}`, {
      parent,
    });
  }

  moveBulkDirectory(ids: string[], parent: string) {
    return this._httpClient.post<any>(`${this._directoryUrl}/bulk/move`, {
      ids,
      parent,
    });
  }

  checkIfUserViewer(data: any): boolean {
    const groups = this._authService.userGroups;
    const userId = this._authService.userId;
    const { userGroups, users, createdBy } = data;
    if (userId === createdBy) {
      return true;
    }

    const byTeams = userGroups?.filter(function (e: any) {
      return groups.some((group: any) => group.userGroupId === e.id);
    });

    const byUsers = users?.filter((i: any) => i.id === userId);

    return [...byTeams, ...byUsers].some(data => data.role === 'EDITOR');
  }

  checkIfUserHasAccess(data: any): boolean {
    if (data.shareToAnyone) {
      return true;
    }

    const groups = this._authService.userGroups;
    const userId = this._authService.userId;
    const { userGroups, users, createdBy } = data;
    if (userId === createdBy) {
      return true;
    }

    const byTeams = userGroups?.filter(function (e: any) {
      return groups.some((group: any) => group.userGroupId === e.id);
    });

    const byUsers = users?.filter((i: any) => i.id === userId);

    const things = [...byTeams, ...byUsers]

    console.log({things})
    if(things.length === 0 ) {
      return false
    }

    console.log({things})
    return things.some(
      data => data.role === 'EDITOR' || data.role === 'VIEWER'
    );
  }

  viewDirectory(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/view/${fileId}`,
      {}
    );
  }

  downloadFolder(id: string) {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    return this._httpClient.get<any>(
      `${this._directoryUrl}/download/folder/${id}`,
      {
        responseType: 'arraybuffer' as 'json',
      }
    );
  }

  downloadBulkFiles(id: string[]) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/bulk/download`,
      {
        ids: id,
      },
      { responseType: 'arraybuffer' as 'json' }
    );
  }

  saveImageState(id: string, rotate: string, hFlip: boolean, vFlip: boolean) {
    const body = { rotate: rotate, horizontalFlip: hFlip, verticalFlip: vFlip };
    return this._httpClient.put<any>(
      `${this._directoryUrl}/update/properties/${id}`,
      body
    );
  }

  getSharedDocuments(fileExtension: string = 'pdf') {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/shared-documents?limit=0&fileExtension=${fileExtension}&sort=0`
    );
  }

  getProxyUrl(id: string) {
    return this._httpClient.get<any>(`${this._directoryUrl}/proxy/${id}`, {
      responseType: 'blob' as 'json',
    });
  }

  blobToBase64(blob: Blob): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(this._sanitizer.bypassSecurityTrustUrl(reader.result as any));
      };
      reader.readAsDataURL(blob);
    });
  }

  getWorkflowData(limit: number = 10) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/recent-workflow?${
        limit !== 0 ? `limit=${limit}` : ''
      }`
    );
  }

  changeExtension(name: string, extension: string) {
    const pos = name.lastIndexOf('.');
    const newName =
      name.substr(0, pos < 0 ? name.length : pos) + '.' + extension;
    return newName;
  }

  verifyFile(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/signsecure/verify/${fileId}`,
      {}
    );
  }

  verifyUserFile(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/signsecure/verify-user/${fileId}`,
      {}
    );
  }

  checkFile(fileId: string) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/lock/${fileId}`,
      {}
    );
  }

  checkIfShared(file: any) {
    return !(file?.shared || file.shareToAnyone) ?? false;
  }

  updateFile(file: File, id: string) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('isQrAdded', JSON.stringify(true));
    return this._httpClient.put<any>(
      `${this._directoryUrl}/update/file/${id}`,
      formData
    );
  }


  getGuestFiles(id: string = this._authService.userId) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/guest/${id}`,
      {}
    );
  }

  indexFile(id: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/index/${id}`,
      {}
    );
  }

  unindexFile(id: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/unindex/${id}`,
      {}
    );
  }
}
