<form class="add-client-container">
  <h4 class="title">Add Client</h4>

  <div class="content">
    <div class="input-group">
      <mat-label class="label">Barcode</mat-label>
      <mat-form-field floatLabel="never" appearance="fill">
        <input type="name" matInput placeholder="First Name" />
      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label class="label">Client Number</mat-label>
      <mat-form-field floatLabel="never" appearance="fill">
        <input type="name" matInput placeholder="First Name" />
      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label class="label">Client Name</mat-label>
      <mat-form-field floatLabel="never" appearance="fill">
        <input type="name" matInput placeholder="First Name" />
      </mat-form-field>
    </div>

    <div class="actions">
      <button matRipple class="cancel">Cancel</button>
      <button matRipple class="create">Add</button>
    </div>
  </div>
</form>
