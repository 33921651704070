import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { environment } from 'src/environments/environment';
import { PublicClientApplication, SsoSilentRequest } from '@azure/msal-browser';
import { AccountComponent } from '../menu/account/account.component';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { catchError, filter, of, throwError } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.sass'],
})
export class SignInComponent implements OnInit {
  @Output() navigatePage = new EventEmitter<string>();

  readonly regFlag = environment.flag.registration;

  screen: string = '';
  hide: boolean = true;
  verification: boolean = false;
  rememberMe: boolean = false;

  loginForm: FormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  errorMsg: string = '';
  previousUrl: string = '';
  currentUrl: string = '/';
  constructor(
    private fb: FormBuilder,
    private _authService: AuthenticationService,
    private _navigation: NavigationService,
    private _snack: MatSnackBar,
    private _router: Router,
    private _signSecure: SignSecureService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (localStorage.length > 0) {
      this.loginForm.controls['username'].setValue(
        localStorage.getItem('username')
      );
      this.loginForm.controls['password'].setValue(
        localStorage.getItem('password')
      );
      this.rememberMe = true;
    }

    if (this._authService.isLogin()) {
      const user = this._authService.getUser();

      if (user.isConfirmed) {
        this._navigation.setLocation('dashboard');
      }
    } else {
      const pubData = this._navigation.hasPublicData()
      const fileData = this._navigation.hasFileData()
      if(!!pubData || !!fileData) {
        this._navigation.checkShareFile(this._dialog)
      }
    }
  }

  login(): void {
    this._signSecure.loginType = 'Email and password'
		localStorage.setItem('loginType', 'Email and password')
    const isLoggedIn = this._authService.isLogin();

    if (this.loginForm.valid &&!isLoggedIn) {
      this._authService
        .login(
          this.loginForm.controls['username'].value,
          this.loginForm.controls['password'].value
        )
        .pipe(
          catchError(err => {
            if (err.status == 401) {
              this.errorMsg = err.error.error;
              this.errorMsg = this.errorMsg.slice(15);
            } else {
              this._snack.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: {
                  type: 'error',
                  text: err?.error?.error?.slice(15) ?? 'Something went wrong',
                },
              });
            }

            return throwError(() => err);
          })
        )
        .subscribe(i => {
          this.setIpInfo()
          if (i.success == true) {

            const role = this._authService.getUserRole(i.data.accessToken)
            if(role === 'guest') {
              this._authService.authenticateUser(i.data.accessToken);
              localStorage.setItem('refreshToken', i.data.refreshToken);
              this._navigation.setLocation(`guest`);
            } else if (i.data.isConfirmed) {
              this._navigation.lastKnownLocation = 'report';
              this._authService.authenticateUser(i.data.accessToken);
              localStorage.setItem('refreshToken', i.data.refreshToken);
              this._authService.rememberMe(
                this.loginForm.controls['username'].value,
                this.loginForm.controls['password'].value,
                this.rememberMe
              );
              this._authService.setRole(this._authService.userRole);
              if(this._authService.userRole === 'guest') {
                this._navigation.setLocation(`guest`);
              } else if(this._navigation.hasPublicData()) {
                const id = this._navigation.getPublicData();
                this._navigation.setLocation(`sign-secure/${id}`);
              } else if(this._navigation.hasFileData()) {
                const id = this._navigation.getFileData();
                this._navigation.setLocation(`file-view/pdf/${id}`);
              }else {
                const location = (this._navigation?.prevLocation == '/' || this._navigation?.prevLocation == '/not-verified-account' || this._navigation?.prevLocation == '/register' || this._navigation?.prevLocation.startsWith('/verify-account')) ? 'dashboard' : this._navigation.prevLocation
                this._navigation.setLocation(location);
              }
              
            } else {
              this._authService.authenticateUser(i.data.accessToken);
              localStorage.setItem('refreshToken', i.data.refreshToken);
              this._router.navigateByUrl('not-verified-account');
              this._authService.setRole(this._authService.userRole);
            }
          }
        });
    } else {
      this.errorMsg = 'Already logged in. Please refresh page.'
    }
  }

  keyLogin(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      this.login();
    }
  }

  async loginAD(): Promise<void> {
    this.setIpInfo()
    const config = {
      auth: {
        clientId: environment.azureAD.clientId,
        authority: environment.azureAD.authority,
        redirectUri: environment.azureAD.redirectUri,
      },
    };
    var client = new PublicClientApplication(config);

    var loginRequest = {
      scopes: ['user.read'],
    };
    let loginResponse = await client.loginPopup(loginRequest);

    var tokenRequest = {
      scopes: ['user.read'],
      account: loginResponse.account ?? undefined,
    };

    const tokenResponse = await client.acquireTokenSilent(tokenRequest);

    let fullname = tokenResponse.account?.name ?? '',
      username = tokenResponse.account?.username ?? '';
    var name = fullname.split(' ', 2);
    // let payload = await fetch("https://graph.microsoft.com/v1.0/me", {
    //     headers: {
    //         'Authorization': 'Bearer ' + tokenResponse.accessToken
    //     }
    // });
    this._authService
      .loginAzureAD(name[0], name[1], username, 'test', 'test')
      .subscribe(i => {
        if (i.success == true) {
          this._navigation.lastKnownLocation = 'report';
          this._authService.authenticateUser(i.data.accessToken);
          sessionStorage.setItem('refreshToken', i.data.refreshToken);
          this._authService.setRole(this._authService.userRole);
        }
      });
  }
  goTo(data: string): void {
    this.navigatePage.emit(data);
  }

  googleLogin() {
    this.setIpInfo()
    this._signSecure.loginType = 'Google login'
		localStorage.setItem('loginType', 'Google login')
    window.location.href = this._authService.googleLoginLink;
  }

  async setIpInfo () {
    // const response = await this._signSecure.callIpInfo()
    const response = await this._signSecure.callIpify()
		localStorage.setItem('ipInfo', JSON.stringify(response))
  }
}
