import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Konva from 'konva';
import { KonvaComponent } from 'ng2-konva';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { PDFDocument } from 'pdf-lib';
import { of, withLatestFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { SignSecureService } from '../services/sign-secure.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../components/modal/modal.component';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { FilesService } from 'src/app/@shared/services/files.service';

@Component({
  selector: 'app-signing-page-content',
  templateUrl: './signing-page-content.component.html',
  styleUrls: ['./signing-page-content.component.sass'],
})
export class SigningPageContentComponent implements OnInit {
  pdfSrc: any = '';
  zoom: number = 1;
  totalPages: number = 1;
  pageNumber: number = 1;

  pageIndex: number = 1;

  @Input()
  data: any;

  @Input()
  status: string = 'PENDING';

  pageHeight: number = 1056;
  pageWidth: number = 886;
  stageConfig = of({
    width: this.pageWidth,
    height: this.pageHeight,
  });

  signatures: any;
  @ViewChild('stage')
  stage!: KonvaComponent;

  layers: any[][] = [[]];

  @Output()
  getUpdateData: EventEmitter<any> = new EventEmitter();

  auditTrail: any[] = [];
  showAuditTrail: boolean = false;

  pdfLoaded: boolean = true;
  positionLeft = `calc(50% - ${this.pageWidth / 2}px)`;

  height: number = 0;
  width: number = 0;
  signingNow: boolean = false;
  isPhoneWidth: boolean = false;

  @Input()
  party: any;

  constructor(
    private _auth: AuthenticationService,
    public dialog: MatDialog,
    private changeDetection: ChangeDetectorRef,
    private _nav: NavigationService,
    private _signSecureService: SignSecureService
  ) {}

  pdf!: PDFDocumentProxy;
  scale: number = 1;
  withPointer = this._nav.getCurrentView() === 'Desktop';

  pageviews: any[] = [];
  ngOnInit(): void {
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }

    this._signSecureService
      .getProxyUrl(this.data.id)
      .subscribe((response: any) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
        };
        fileReader.readAsArrayBuffer(response);
      }); // reading of pdf

    this.signatures =
      this.data?.workflow?.signatures?.map(
        (page: any) =>
          page?.filter((signature: any) => {
            return (
              signature.type === 'name' ||
              signature.type === 'designation' ||
              signature.type === 'date-time' ||
              signature.id === this._auth.userId ||
              signature.email === this._auth.email
            );
          }) ?? []
      ) ?? [];

    console.log({
      status: this.status,
      workflow: this.data?.workflow,
      id: this._auth.userId,
    });
    this.signingNow =
      (!this.data?.workflow?.signOrder && !this.party.executedFormatted) ||
      this.data?.workflow?.currentParty === this._auth.userId ||
      this.data?.workflow?.currentParty === '' ||
      this.data?.workflow?.currentParty === this._auth.email;

    this.auditTrail = this.data?.workflow?.auditTrail ?? [];
    this.updateData();
  }

  async afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.totalPages = pdf?.numPages;
    this.layers = Array(this.totalPages).fill([]);
    this.pageviews = Array(this.totalPages).fill({});
    // await this.getPageInfo(pdf);
  }

  async getPageInfo(pdf: PDFDocumentProxy) {
    const pageView = await pdf.getPage(this.pageNumber);
    const view = pageView.getViewport();
    const viewport =
      view?.viewBox ??
      this.pageviews[this.pageNumber - 1]?.viewport?.viewBox ??
      [];

    if (viewport.length === 4) {
      const scale = this.pageviews[this.pageNumber - 1]?.viewport?.scale;
      //[ scalex, scaley, width, height, zoom]
      this.stageConfig = of({
        width: viewport[2],
        height: viewport[3],
      });
      if (this.withPointer) {
        this.pageWidth = viewport[2] * PdfViewerComponent.CSS_UNITS;
        this.pageHeight = viewport[3] * PdfViewerComponent.CSS_UNITS;
        this.positionLeft = `calc(50% - ${this.pageWidth / 2 + 72}px)`;
        this.stage.getStage().setSize({
          width: viewport[2] * PdfViewerComponent.CSS_UNITS + 72,
          height: viewport[3] * PdfViewerComponent.CSS_UNITS,
        });
      } else {
        this.stageConfig = of({
          width: viewport[2],
          height: viewport[3],
        });

        this.pageWidth = viewport[2] * PdfViewerComponent.CSS_UNITS;
        this.pageHeight = viewport[3] * PdfViewerComponent.CSS_UNITS;
        this.positionLeft = `calc(50% - ${Math.ceil(
          this.pageviews[this.pageNumber - 1]?.viewport.width / 2
        )}px)`;
        this.stage.getStage().setSize({
          width: viewport[2] * PdfViewerComponent.CSS_UNITS,
          height: viewport[3] * PdfViewerComponent.CSS_UNITS,
        });
      }

      this.width = viewport[2];
      this.height = viewport[3];

      // this.changeDetection.detectChanges();
    }

    this.createSigningLocation();
  }

  async pageNavi(navi: string) {
    switch (navi) {
      case 'up':
        if (this.pageNumber > 1) {
          this.pageNumber = this.pageNumber - 1;
          await this.getPageInfo(this.pdf);
        }
        break;
      case 'down':
        if (this.pageNumber < this.totalPages) {
          this.pageNumber = this.pageNumber + 1;
          await this.getPageInfo(this.pdf);
        }
        break;
      default:
        break;
    }
  }

  createSigningLocation(scale: number = this.scale) {
    console.log({ status: this.status, signingNow: this.signingNow });
    if (
      this.status === 'COMPLETED' ||
      this.status === 'CANCELLED' ||
      this.status === 'DECLINED' ||
      this.status === 'DONE' ||
      this.status === 'EXPIRED' ||
      !this.signingNow
    ) {
      return;
    }

    const stage: Konva.Stage = this.stage.getStage();
    const locationScale = 1;
    stage.clear();
    stage.clearCache();
    stage.destroyChildren();

    stage.on('click tap', (event: any) => {
      if (event?.target instanceof Konva.Rect) {
        this.showAddSignature(event?.target?.attrs);
      } else if (event?.target instanceof Konva.Image) {
        const rect = event?.target?.parent?.children?.find(
          (child: any) => child instanceof Konva.Rect
        ) ?? { attrs: {} };
        this.showAddSignature(rect?.attrs);
      }
    });

    const widthCalc = this.pageWidth / this.width;
    const heightCalc = this.height / this.pageHeight;
    const aspect = (this.width * locationScale) / (this.height * locationScale);
    const page = this.pageNumber - 1;
    this.signatures[page]?.forEach((signature: any, index: number) => {
      if (this.layers[page][index]) {
        const layer: Konva.Layer = this.layers[page][index];
        layer.destroyChildren();
      } else {
        this.layers[page].push(new Konva.Layer());
      }

      let groupConfig: any = {};

      let isTransformed = false;
      if (signature.transform) {
        const caclulatedX =
          +signature.transform.x + (this.withPointer ? 72 : 0);

        groupConfig.scaleX = +signature.transform.scaleX;
        groupConfig.scaleY = +signature.transform.scaleY;
        groupConfig.rotation = +signature.transform.rotation;
        groupConfig.skewX = +signature.transform.skewX;
        groupConfig.skewY = +signature.transform.skewY;
        groupConfig.x = caclulatedX;
        groupConfig.y = +signature.transform.y;
        isTransformed = true;
      }

      const fieldConfig = {
        x: +signature?.config?.x * locationScale + (this.withPointer ? 72 : 8), //+ (this.withPointer ? 0 : +signature.config.width * locationScale),
        y: +signature?.config?.y * locationScale,
        height: +signature?.config?.height * locationScale,
        width: +signature?.config?.width * locationScale,
        scale: locationScale,
      };

      const group = new Konva.Group(groupConfig);
      console.log({ fieldConfig, groupConfig, signature });

      if (!isNaN(fieldConfig.x) && !isNaN(fieldConfig.y)) {
        group.setPosition({ x: fieldConfig.x, y: fieldConfig.y });
      }

      if (signature.signature) {
        Konva.Image.fromURL(signature.signature, (img: Konva.Image) => {
          signature.signatureData = img.toDataURL();

          const aspectRatio = this.getAspectRatioSize(
            { width: img.getWidth(), height: img.getHeight() },
            signature.config
          );

          console.log('signature size: ', {
            width: img.getWidth(),
            height: img.getHeight(),
          });

          const rect = new Konva.Rect({
            ...signature.config,
            x: 0,
            y: 0,
            width: fieldConfig.width * locationScale, //+ 72,
            height: fieldConfig.height * locationScale, //+ 72,
            fill: '#000',
            opacity: 0,
            page: this.pageNumber - 1,
            data: signature,
            index,
          });
          group.add(rect);

          const rectWidth = rect.width();
          const rectHeight = rect.height();
          /**
           * START:: Center signature in the box
           */
          const imageWidth = img.getWidth();
          const imageHeight = img.getHeight();
          const imageAspectRatio = imageWidth / imageHeight;
          const rectangleAspectRatio = rectWidth / rectHeight;

          let scaledWidth, scaledHeight;
          if (imageAspectRatio > rectangleAspectRatio) {
            scaledWidth = rectWidth;
            scaledHeight = rectWidth / imageAspectRatio;
          } else {
            scaledHeight = +rectHeight;
            scaledWidth = rectHeight * imageAspectRatio;
          }

          const view = this._nav.getCurrentView();
          console.log({
            scaledWidth,
            rectWidth,
          });
          const imageX = rectWidth / 2 - Math.round(scaledWidth * 0.35);

          console.log({
            scaledWidth,
            rectWidth,
            imageX,
            aspectRatio,
            signature: signature.config,
          });
          // (this.withPointer
          // 	? scaledWidth > signature.config.width * 0.5
          // 		? scaledWidth / 2
          // 		: scaledWidth
          // 	: view === "Tablet"
          // 		? scaledWidth / 2
          // 		: 0)

          const imageY =
            (+signature?.config?.height - scaledHeight) / 2 +
            (view === 'Tablet' ? 15 : 30);

          /**
           * END:: Center signature in the box
           */

          const imgWithoutPointerX =
            fieldConfig.x * locationScale +
            imageX * locationScale -
            (this.withPointer ? 0 : 25);
          const imgWithoutPointerY =
            fieldConfig.y * locationScale + 15 * locationScale;

          img.setAttrs({
            x: imageX,
            y: imageY,
            ...aspectRatio,
            listening: false,
            name: 'signatureConfig',
          });
          signature.pageSize = stage.size();

          const groupPosition = group.getPosition();
          signature.signatureConfig = {
            x: groupPosition.x + imageX,
            y: groupPosition.y + imageY,
            ...aspectRatio,
            listening: false,
            name: 'signatureConfig',
          };

          signature.actualSize = { width: this.width, height: this.height };

          // signature id
          group.add(
            new Konva.Text({
              ...signature.documentConfig,
              x: +signature.documentConfig.x - 65,
              y: +signature.documentConfig.y,
              fontSize: +signature.documentConfig.fontSize * locationScale,
              text: signature.signatureId,
              width: 200,
              listening: false,
              name: 'documentConfig',
            })
          );

          group.add(img);

          Konva.Image.fromURL(
            '../../../assets/images/signature-logo.png',
            (img: any) => {
              const withoutPointerX = fieldConfig.x * locationScale;
              const withoutPointerY =
                fieldConfig.y * locationScale + 10 * locationScale;
              img.setAttrs({
                x: 0,
                y: 10,
                width: 22 * locationScale,
                height: 6 * locationScale,
                listening: false,
              });
              signature.sigData = {
                image: img.toDataURL(),
                x: +signature.config.x / PdfViewerComponent.CSS_UNITS,
                y: +signature.config.y / PdfViewerComponent.CSS_UNITS,
                width: 22 * locationScale,
                height: 6 * locationScale,
              };
              group.add(img);
            }
          );
        });
      } else if (
        signature.type.includes('signature') ||
        signature.type === 'initials'
      ) {
        if (this.withPointer) {
          // get the centered height of the signature box even if it's rotated
          const rectWidth = fieldConfig.width;
          const rectHeight = fieldConfig.height;
          const rectCenterX = rectWidth / 2;
          const rectCenterY = rectHeight / 2;
          const rectAngle = group.rotation() * (Math.PI / 180);
          const rectCos = Math.cos(rectAngle);
          const rectSin = Math.sin(rectAngle);
          const rectCenterYPrime =
            rectCenterX * rectSin + rectCenterY * rectCos;

          Konva.Image.fromURL(
            '../../../assets/images/custom-rectangle.svg',
            (img: any) => {
              img.setAttrs({
                x: 0,
                y: fieldConfig.y + rectCenterYPrime - 19,
                width: 113,
                height: 38,
                listening: false,
              });

              this.layers[page][index].add(img);
              this.layers[page][index].add(
                new Konva.Text({
                  x: 0,
                  y: fieldConfig.y + rectCenterYPrime - 19,
                  text:
                    signature.type === 'initials'
                      ? 'Initial here'
                      : 'Sign here',
                  fontSize: 14,
                  fontFamily: 'DM Sans',
                  width: 150,
                  height: 25,
                  padding: 13,
                  align: 'left',
                  listening: false,
                })
              );
            }
          );
        }

        const x = fieldConfig.width / 2;
        const y = fieldConfig.height / 2;

        const signImage = new Konva.Path({
          ...signature.textConfig,
          x,
          y,
          data: 'M18.2717 2.3467C17.8513 2.3467 17.4481 2.5091 17.1509 2.79817L2.87121 16.6835L2.12395 19.59L5.113 18.8634L19.3926 4.97806C19.5398 4.83492 19.6566 4.665 19.7363 4.47799C19.8159 4.29098 19.8569 4.09054 19.8569 3.88811C19.8569 3.68569 19.8159 3.48525 19.7363 3.29824C19.6566 3.11123 19.5398 2.9413 19.3926 2.79817C19.2454 2.65504 19.0707 2.5415 18.8784 2.46403C18.686 2.38657 18.4799 2.3467 18.2717 2.3467ZM15.9248 1.60595C16.5472 1.00068 17.3915 0.660645 18.2717 0.660645C18.7076 0.660645 19.1392 0.744125 19.5419 0.906321C19.9446 1.06852 20.3105 1.30625 20.6187 1.60595C20.9269 1.90565 21.1714 2.26144 21.3382 2.65301C21.505 3.04459 21.5909 3.46428 21.5909 3.88811C21.5909 4.31195 21.505 4.73164 21.3382 5.12321C21.1714 5.51479 20.9269 5.87058 20.6187 6.17028L6.16927 20.2207C6.05816 20.3288 5.91895 20.4054 5.76651 20.4425L1.14268 21.5665C0.847244 21.6383 0.534714 21.5542 0.319377 21.3448C0.104039 21.1354 0.0174734 20.8315 0.0913335 20.5442L1.24729 16.0481C1.2854 15.8998 1.36422 15.7645 1.47533 15.6564L15.9248 1.60595ZM10.469 20.7487C10.469 20.2831 10.8572 19.9056 11.336 19.9056H21.7396C22.2184 19.9056 22.6066 20.2831 22.6066 20.7487C22.6066 21.2143 22.2184 21.5917 21.7396 21.5917H11.336C10.8572 21.5917 10.469 21.2143 10.469 20.7487Z',
        });

        group.add(
          new Konva.Rect({
            ...signature.config,
            x: 0,
            y: 0,
            width: fieldConfig.width,
            height: fieldConfig.height,
            dash: [4],
            page: this.pageNumber - 1,
            data: signature,
            index,
            stroke: signature.color,
            name: 'config',
          })
        );

        group.add(
          new Konva.Text({
            ...signature.textConfig,
            x: x - 80,
            y: y + 1,
            text: signature.type === 'initials' ? 'Initial' : 'Sign',
            fontSize: 12 * locationScale,
            fontFamily: 'DM Sans',
            width: 150 * locationScale,
            height: 25 * locationScale,
            padding: 13 * locationScale,
            align: 'center',
            listening: false,
            name: 'textConfig',
          })
        );

        signImage.setAttrs({
          x: x - 11.5,
          y: y - 13,
          width: 23,
          height: 21,
          listening: false,
          scale: locationScale,
          index,
        });

        signImage.listening(false);
        group.add(signImage);
        // })
      }

      if (signature.nameConfig) {
        let xName = +signature.nameConfig.x * locationScale
        let yName = +signature.nameConfig.y * locationScale
        if (isNaN(fieldConfig.x) && isNaN(fieldConfig.y)) {
          group.setPosition({
            x: xName + (this.withPointer ? 72 : 8),
            y: yName,
          });
          
          yName = 0
          xName = 0
        }

        const nameText = new Konva.Text({
          ...signature.nameConfig,
          x: xName,
          y: yName,
          fontSize: +signature.nameConfig.fontSize * locationScale,
          listening: false,
          name: 'nameConfig',
        });
        group.add(nameText);

        const { x, y } = nameText.absolutePosition();
        signature.nameConfig = {
          ...signature.nameConfig,
          absLocation: { x: x - (this.withPointer ? 72 : 0), y },
        };
      }

      if (signature.dateConfig) {

        let locationX = +signature.dateConfig.x * locationScale
        let locationY = +signature.dateConfig.y * locationScale
      

        if (isNaN(fieldConfig.x) && isNaN(fieldConfig.y)) {
          group.setPosition({
            x: locationX+ (this.withPointer ? 72 : 8),
            y: locationY,
          });

          locationX = 0
          locationY = 0
        }

        const dateText = new Konva.Text({
          ...signature.dateConfig,
          x: locationX,
          y: locationY,
          fontSize: +signature.dateConfig.fontSize * locationScale,
          listening: false,
          name: 'dateConfig',
        });
        group.add(dateText);

        const { x, y } = dateText.absolutePosition();
        signature.dateConfig = {
          ...signature.dateConfig,
          absLocation: { x: x - (this.withPointer ? 72 : 0), y },
        };
      }

      if (signature.desginationConfig) {
        let locationX = +signature.desginationConfig.x * locationScale
        let locationY = +signature.desginationConfig.y * locationScale
      

        if (isNaN(fieldConfig.x) && isNaN(fieldConfig.y)) {
          group.setPosition({
            x: locationX+ (this.withPointer ? 72 : 8),
            y: locationY,
          });

          locationX = 0
          locationY = 0
        }

        const desginationText = new Konva.Text({
          ...signature.desginationConfig,
          x: locationX,
          y: locationY,
          fontSize: +signature.desginationConfig.fontSize * locationScale,
          listening: false,
          name: 'desginationConfig',
        });
        group.add(desginationText);

        const { x, y } = desginationText.absolutePosition();
        signature.desginationConfig = {
          ...signature.desginationConfig,
          absLocation: { x: x - (this.withPointer ? 72 : 0), y },
        };
      }

      const trans = new Konva.Transformer({
        borderDash: [4],
        borderStroke: 'dash',
        borderStrokeWidth: 2,
        borderEnabled: true,
        flipEnabled: false,
        padding: 8,
        draggable: false,
        resizeEnabled: false,
        rotateEnabled: false,
      });

      group.add(trans);
      this.layers[page][index].add(group);
      this.layers[page][index].batchDraw();
      this.signatures[page][index] = signature;
      stage.add(this.layers[page][index]);
      stage.batchDraw();

      signature.absPosition = group.absolutePosition();
      signature.size = { width: group.width(), height: group.height()}
    });

    this.updateData();
  }

  showAddSignature(data: any) {
    if (this.layers[this.pageNumber - 1][data.index]?.signature) return;
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(ModalComponent, {
        width: '542px',
        maxWidth: '100vw',
        data: {
          action:
            data?.data?.type === 'initials'
              ? 'create-initial'
              : 'create-signature',
          data: data,
        },
      });

      dialogRef?.afterClosed()?.subscribe(signature => {
        if (signature && data.index !== undefined) {
          console.log({ signature });
          this.signatures[this.pageNumber - 1][data.index] = {
            ...data.data,
            signature: signature?.path ?? signature,
            // signatureId data is assigned in create-signature
            signatureId: this._signSecureService.signatureId,
          };
          this.updateData();
          this.createSigningLocation();
        }
        this.dialog.closeAll();
      });
    }
  }

  updateData() {
    this.getUpdateData.emit([...this.signatures]);
  }

  getAspectRatioSize(image: any, size: any) {
    let newHeight = (size.height - 30) / image.height;

    let newWidth = image.width * newHeight;

    return { ...image, width: newWidth, scaleY: newHeight };
  }

  zoomLevel(level: string) {
    switch (level) {
      case 'in':
        if (this.zoom <= 2.75) {
          this.zoom = +this.zoom + +0.25;
        }
        break;
      case 'out':
        if (this.zoom >= 0.5) {
          this.zoom = this.zoom - 0.25;
        }
        break;
      default:
        break;
    }
  }

  test(event: any) {
    const { source, pageNumber } = event;
    if (!source) {
      return;
    }

    this.pageviews[pageNumber - 1] = source;

    if (pageNumber !== this.pageNumber) return;

    const stage = this.stage.getStage() as Konva.Stage;
    this.scale = source.scale;

    if (this.withPointer) {
      stage.setSize({
        width: this.width * this.scale + 72,
        height: this.height * this.scale,
      });
      this.positionLeft = `calc(50% - ${(this.width * this.scale) / 2 + 72}px)`;
    } else {
      stage.setSize({
        width: (this.width * this.scale).toFixed(0),
        height: (this.height * this.scale).toFixed(0),
      });
      stage.scale({ x: this.scale, y: this.scale });
      this.positionLeft = `calc(50% - ${(this.width * this.scale) / 2}px)`;
    }

    this.changeDetection.detectChanges();
    this.getPageInfo(this.pdf);
    // this.createSigningLocation(source.viewport.scale);
  }

  calcLocation(location: number, orig: number, value: number) {
    return location - (location * value) / orig;
  }

  print() {
    try {
      this.pdf.getData().then((u8: any) => {
        let blob = new Blob([u8.buffer], {
          type: 'application/pdf',
        });

        const blobUrl = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe?.contentWindow?.print();
      });
    } catch (e) {
      console.log('ERROR', e);
      window.print();
    }
  }
}
