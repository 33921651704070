<mat-card class="progress-card">
  <div class="content">
    <div class="text">
      <p class="number-docs">{{ number }}</p>
      <h5 class="title">{{ title }}</h5>
    </div>
  </div>
  <div class="footer" (click)="navigateTo(location)">
    <p>See all</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M8 5l7 7-7 7-1.41-1.41L12.17 12 6.59 6.41 8 5z" fill="#A42B20"/>
    </svg>    
  </div>
</mat-card>
