import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { DashBoardServices } from 'src/app/@shared/services/dashboard.service';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SettingsService } from 'src/app/@shared/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.sass'],
})
export class AdminSidebarComponent implements OnInit {
  version: string = '';
  userType: string = '';
  readonly unawaLogoFlag = environment.flag.unawaLogo;
  selected: string = 'report';
  dashRep: boolean = true;
  manageUser: boolean = false;
  manageUserGrp: boolean = false;
  auditLog: boolean = false;
  name: string = "";
  email: string = "";
  docMenu: boolean = false;
  listMenu: boolean = false;
  docDetailMenu: boolean = false;
  logoSrc = this._settingsService.logoSrc$
  readonly hideItem = environment.flag.hideSidebarMenu;
  readonly signaturesMenuFlag = environment.flag.signatureMenu;
  @Input() sideNavToggle: boolean = true;

  filter = 'invert(38%) sepia(25%) saturate(2923%) hue-rotate(166deg) brightness(96%) contrast(87%)'

  constructor(
    private _navigation: NavigationService,
    private _authService: AuthenticationService,
    private _fileActionService: FileActionService,
    private _dashboardService: DashBoardServices,
    private _router: Router,
    private _settingsService: SettingsService
  ) {
    this._navigation.location$.subscribe(
      (location) => (this.selected = location)
    );


  }



  ngOnInit(): void {
    this.version = this._dashboardService.appVersion;
    this._navigation.initLocation = this.selected;
    this.userType = this._authService.userRole;

    this.name = this._authService.name
    this.email = this._authService.email 
  }

  updateLocation(location: string) {
    this._router.navigateByUrl(location);
    this._navigation.updateLocation(location);
    if (location !== 'docs') {
      this._fileActionService.selectedTab = 0;
    }

    this._fileActionService.setIsModify(false);
  }

  closeSidebar() {
    if (screen.width >= 375 && screen.width <= 600) {
      this.sideNavToggle = false
    } else if (screen.width >= 600 && screen.width <= 912) {
      this.sideNavToggle = true
    }
  }

  logout() {
    this._authService.logout();
    sessionStorage.removeItem('token');
    this._authService.userRole = '';
    this._authService.setRole(this._authService.userRole);
    this._router.navigateByUrl('/login');
  }
}
